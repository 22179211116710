import Head from 'next/head';

import React, { FC } from 'react';
import { Landing } from 'src/components/Landing/Landing';

const IndexPage: FC = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>MX.pro - умная сделка</title>
      </Head>
      <Landing />
    </>
  );
};

export default IndexPage;
